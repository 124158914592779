<template>
  <a-modal title="退费编辑" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number style="width:100%" v-if="item.type === 'inputNumber'" :min="0" v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
    {
        name: 'receive_total',
        label: '活动项目总价',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
    {
        name: 'finish_times',
        label: '已完成活动（次/天数）',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
    {
        name: 'costs_price',
        label: '已完成活动费用',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
    {
        name: 'fine_price',
        label: '项目分档违约金',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
    {
        name: 'service_charge',
        label: '项目手续费（%）',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
    {
        name: 'refund_total',
        label: '应退金额',
        type: 'inputNumber',
        rules: [{ required: true, message: '请输入!' }],
    },
]
    export default {
        name:'editModal',
        inject: ['parent'],
        data() {
            return {
                formData,
                visible: false,
                loading: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
            }
        },
        props: {
            item: Object,
        },
        created () {
            this.visible = true
            this.getDetail()
        },
        methods: {
            async getDetail() {
                this.loading = true
                await this.$store.dispatch('zhuqiaoApplyDetailAction',{id:this.item.id})
                .then(res=>{
                    this.$nextTick(()=>{
                        this.form.setFieldsValue({
                            receive_total: res.data.receive_total || 0,
                            finish_times: res.data.finish_times || 0,
                            costs_price: res.data.costs_price || 0,
                            fine_price: res.data.fine_price || 0,
                            service_charge: res.data.service_charge || 0,
                            refund_total: res.data.refund_total || 0,
                        })
                        this.loading = false
                    })
                })
            },
            async handleOk(){
                let params = await this.form.validateFields()
                params.id = this.item.id
                this.confirmLoading = true
                await this.$store.dispatch('zhuqiaoApplyUpdateAction',params)
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.confirmLoading = false
                })
                .catch(err=>{
                    this.confirmLoading = false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>