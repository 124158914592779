<template>
    <div>
        <div>
            <div class="clearfix table-tools">
                <div class="buttons"></div>
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>学员姓名</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.student_name" placeholder="请输入学员姓名" style="width: 180px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate"
                            @change="(date, dateString)=>onChange(date, dateString,2)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table :pagination="false" :bordered='false' rowKey="id" :columns="columns" :dataSource="list" :scroll="{ x: 2000 }" >
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="image" slot-scope="text">
                        <LImg :preview="0" :src="text" style="height: 30px"/>
                    </template>
                    <template slot="action" slot-scope="text,record">
                        <a href="javascript:;" @click="showEditModal(record)">编辑</a>
                        <a-divider type="vertical" />
                        <a href="javascript:;" @click="toExport(record)">下载</a>
                    </template>
                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
        </div>
        <editModal v-if='isEditModal' :item="modalData" />
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'80px', scopedSlots: { customRender: 'index' } , key: 'index' ,fixed: 'left'},
  { title: '学员名称', dataIndex: 'student_name', key: 'student_name'},
  { title: '退费课程', dataIndex: 'refund_project_name', key: 'refund_project_name'},
  { title: '退费原因', dataIndex: 'refund_reason', key: 'refund_reason'},
  { title: '活动项目总价', width: 140, dataIndex: 'receive_total', key: 'receive_total'},
  { title: '已完成活动（次/天数）', width: 180, dataIndex: 'finish_times', key: 'finish_times'},
  { title: '已完成活动费用', width: 140, dataIndex: 'costs_price', key: 'costs_price'},
  { title: '项目分档违约金', width: 140, dataIndex: 'fine_price', key: 'fine_price'},
  { title: '项目手续费（%）', width: 150, dataIndex: 'service_charge', key: 'service_charge'},
  { title: '应退金额', dataIndex: 'refund_total', key: 'refund_total'},
  { title: '开户行', dataIndex: 'bank_name', key: 'bank_name'},
  { title: '户名', dataIndex: 'bank_account_name', key: 'bank_account_name'},
  { title: '银行账号', dataIndex: 'bank_account', key: 'bank_account'},
  { title: '家长签名', dataIndex: 'signature', key: 'signature', scopedSlots: { customRender: 'image' }},
  { title: '申请时间', dataIndex: 'created_at', key: 'created_at'},
  { title: '操作', key: 'operation', width: 120, align: 'right', scopedSlots: { customRender: 'action' }, fixed: 'right'},
]

    import editModal from './editModal'
    import moment from 'moment'
    import ranges from "@/common/mixins/ranges"
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'refund',
        data() {
            return {
                columns,
                list: [],
                loading: false,
                isEditModal: false,
                exportLoading: false,
                paramsData:{
                    start_date:'',
                    end_date:'',
                },
                modalData:{}

            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
        },
        mixins:[ tableMixins, ranges ],
        methods: {
            async getList() {
                this.loading = true
                this.searchParams.search.start_date = this.paramsData.start_date
                this.searchParams.search.end_date = this.paramsData.end_date
                await this.$store.dispatch('zhuqiaoApplyAction',this.searchParams)
                .then(res=>{
                    this.loading = false
                    this.list = res.items
                    this.pageParams = res._meta
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(){
                this.isEditModal = false
            },
            async toExport(item){
                this.exportLoading = true
                let res = await this.$store.dispatch('zhuqiaoApplyExportAction', {id:item.id})
                const blob = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `退费申请.${moment().format('YYYY.MM.DD')}.docx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>