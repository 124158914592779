<template>
    <div>
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>校区管理</a-breadcrumb-item>
            <a-breadcrumb-item>筑桥应用</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-body students">
            <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
                <a-tab-pane tab="退费申请" key="1">
                    <refund v-if="activeKey==='1'"/>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import refund from './refund/index'

    export default {
        name:'huidong',
        data() {
            return {
                activeKey: '1'
            }
        },
        components: {
            refund
        },
        methods: {
            changeTab(key) {
                this.activeKey = key
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>